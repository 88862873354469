import React from 'react';
import './App.css';
import {
  Box,
  // Button, 
  Flex, Icon, Image, ListItem, Text, UnorderedList, useMediaQuery
} from '@chakra-ui/react';
import { TbCalendarTime } from 'react-icons/tb';
import { AiOutlineEuroCircle } from 'react-icons/ai';
import { BsTelephoneFill } from 'react-icons/bs';
import { IoMdMail } from 'react-icons/io';

function App() {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const [isLargerThan425] = useMediaQuery('(min-width: 425px)')

  // function scrollTo(elementId: string): void {
  //   const elementToScrollTo = document.getElementById(elementId)
  //   if (elementToScrollTo) {
  //     const y = elementToScrollTo.offsetTop - 60;
  //     document.getElementById("container")?.scrollTo({
  //       top: y,
  //       behavior: 'smooth'
  //     });
  //   }
  // }
  return (
    <Box height="100vh" overflowY={"hidden"}>
      {/* NAVBAR */}
      {/* <Box as="section">
        <Box as="nav" bg="bg-surface" boxShadow="sm">
          <Container py={{ base: '4', lg: '5' }}>
            <HStack spacing="10" justify="space-between">
              <Flex justify="space-between" flex="1">
                <ButtonGroup variant="link" spacing="8">
                  {['Home', 'Produits', 'Présentation', 'Plan'].map((item) => (
                    <Button key={item}>{item}</Button>
                  ))}
                </ButtonGroup>
              </Flex>
            </HStack>
          </Container>
        </Box>
      </Box> */}
      {/* <Box position={"fixed"} width="100%">
        <Flex backgroundColor="#7AB367" justifyContent={"center"} height="60px" gap="15px">
          <Button width="125px" height="100%" colorScheme="whatsapp" onClick={() => scrollTo("firstPart")}>Home</Button>
          <Button width="125px" height="100%" colorScheme="whatsapp" onClick={() => scrollTo("titleProductsPart")}>Produits</Button>
          <Button width="125px" height="100%" colorScheme="whatsapp" onClick={() => scrollTo("presentationPart")}>Présentation</Button>
          <Button width="125px" height="100%" colorScheme="whatsapp" onClick={() => scrollTo("planPart")}>Plan</Button>
        </Flex >
      </Box > */}
      {/* @ts-ignore */}
      <Flex id="container" flexDirection={"column"} height={"100%"} overflowY="auto">
        {/* PICTURE + TEXT PART */}
        {isLargerThan768 ? < Flex id="firstPart" flexDirection={"row"} width={"100%"}>
          <Image width="50%" src={require("./qi_gong.jpg")} />
          <Flex width={"50%"} flexDirection={'column'} color="#14453D" gap="15px" padding="5%" justifyContent={"center"}>
            <Text fontSize={"36px"} >QI GONG art de longévité</Text>
            <Text fontSize={"20px"}>Recherche d’équilibre et d’harmonie par des mouvements pratiqués en étant présent à sa respiration et conscient de son intention.</Text>
          </Flex>
        </Flex > : isLargerThan425 ? < Flex id="firstPart" flexDirection={"row"} width={"100%"}>
          <Flex overflow={'hidden'} position={'relative'} flexDirection={'column'} minHeight={'233px'} color="#14453D" gap="15px" padding="3% 14%" justifyContent={"center"}>
            <Image src={require("./qi_gong.jpg")} alt={'qi_gong.jpg'} opacity={0.4} position={'absolute'} left={0} top={0} width={'100%'} height={'auto'} />
            <Box position={'relative'} textAlign={'center'}>
              <Text fontSize={"26px"} >QI GONG art de longévité</Text>
              <Text fontSize={"18px"}>Recherche d’équilibre et d’harmonie par des mouvements pratiqués en étant présent à sa respiration et conscient de son intention.</Text>
            </Box>
          </Flex>
        </Flex > : < Flex id="firstPart" flexDirection={"row"} width={"100%"}>
          <Flex overflow={'hidden'} position={'relative'} flexDirection={'column'} minHeight={'174px'} color="#14453D" gap="15px" padding="3% 8%" justifyContent={"center"}>
            <Image src={require("./qi_gong.jpg")} alt={'qi_gong.jpg'} opacity={0.4} position={'absolute'} left={0} top={0} width={'100%'} height={'auto'} />
            <Box position={'relative'} textAlign={'center'}>
              <Text fontSize={"20px"} >QI GONG art de longévité</Text>
              <Text fontSize={"15px"}>Recherche d’équilibre et d’harmonie par des mouvements pratiqués en étant présent à sa respiration et conscient de son intention.</Text>
            </Box>
          </Flex>
        </Flex >}
        {/* QUOTE PART */}
        {isLargerThan768 ? < Flex backgroundColor={"#ECECE7"} justifyContent="center" >
          <Flex flexDirection={"column"} textAlign="center" color="#14453D" maxWidth="750px" padding="60px 0" margin={'0 50px'} gap="15px">
            <Text fontSize={"26px"} marginBottom="15px">« Comme l’énergie est une source continue qui nous maintient en vie chaque jour, nous devons apprendre à vivre avec elle, à l’incorporer, à la libérer si nécessaire et à la régénérer en nous. »</Text>
            <Text fontSize="18px">- Chung Liang</Text>
          </Flex>
        </Flex > : isLargerThan425 ? < Flex backgroundColor={"#ECECE7"} justifyContent="center" >
          <Flex flexDirection={"column"} textAlign="center" color="#14453D" width="750px" padding="4% 11%" gap="15px">
            <Text fontSize={"22px"} marginBottom="15px">« Comme l’énergie est une source continue qui nous maintient en vie chaque jour, nous devons apprendre à vivre avec elle, à l’incorporer, à la libérer si nécessaire et à la régénérer en nous. »</Text>
            <Text fontSize={"16px"}>- Chung Liang</Text>
          </Flex>
        </Flex > : < Flex backgroundColor={"#ECECE7"} justifyContent="center" >
          <Flex flexDirection={"column"} textAlign="center" color="#14453D" width="750px" padding="4% 11%" gap="15px">
            <Text fontSize={"16px"} marginBottom="15px">« Comme l’énergie est une source continue qui nous maintient en vie chaque jour, nous devons apprendre à vivre avec elle, à l’incorporer, à la libérer si nécessaire et à la régénérer en nous. »</Text>
            <Text fontSize={"13px"}>- Chung Liang</Text>
          </Flex>
        </Flex >}
        {/* DEFINITION PART */}
        {isLargerThan768 ? < Flex backgroundColor={"#14453D"} justifyContent="center" >
          <Flex flexDirection={"column"} fontWeight="500" color="white" maxWidth="750px" padding="60px 0 50px" margin={'0 50px'} gap="15px">
            <Text fontSize={"30px"} textAlign="center" marginBottom="35px">Un des premiers effets constatés de la pratique est un état de détente, un relâchement propice à une circulation interne fluide et à la physiologie</Text>
            <Text fontSize="19px">La pratique régulière permet de :</Text>
            <UnorderedList fontSize="19px" p={2} marginLeft="45px">
              <ListItem>Diminuer le stress, soulager des douleurs</ListItem>
              <ListItem>Mieux dormir, mieux digérer, mieux respirer, nourrir son énergie</ListItem>
              <ListItem>Libérer, assouplir et consolider les articulations</ListItem>
            </UnorderedList>
            <Text fontSize="19px">Le qi gong est un outil de la médecine chinoise dont le plus connu est l’acupuncture.</Text>
          </Flex>
        </Flex > : isLargerThan425 ? < Flex backgroundColor={"#14453D"} justifyContent="center" >
          <Flex flexDirection={"column"} fontWeight="500" color="white" width="750px" padding="4% 11%" gap="15px">
            <Text fontSize={"24px"} textAlign="center" marginBottom="20px">Un des premiers effets constatés de la pratique est un état de détente, un relâchement propice à une circulation interne fluide et à la physiologie</Text>
            <Text fontSize={"16px"}>La pratique régulière permet de :</Text>
            <UnorderedList fontSize={"16px"} p={2} marginLeft="45px">
              <ListItem>Diminuer le stress, soulager des douleurs</ListItem>
              <ListItem>Mieux dormir, mieux digérer, mieux respirer, nourrir son énergie</ListItem>
              <ListItem>Libérer, assouplir et consolider les articulations</ListItem>
            </UnorderedList>
            <Text fontSize={"16px"} textAlign={'center'}>Le qi gong est un outil de la médecine chinoise dont le plus connu est l’acupuncture.</Text>
          </Flex>
        </Flex > : < Flex backgroundColor={"#14453D"} justifyContent="center" >
          <Flex flexDirection={"column"} fontWeight="500" color="white" width="750px" padding="4% 11%" gap="15px">
            <Text fontSize={"18px"} textAlign="center" marginBottom="10px">Un des premiers effets constatés de la pratique est un état de détente, un relâchement propice à une circulation interne fluide et à la physiologie</Text>
            <Text fontSize={"13px"}>La pratique régulière permet de :</Text>
            <UnorderedList fontSize={"13px"} p={2} marginLeft="45px">
              <ListItem>Diminuer le stress, soulager des douleurs</ListItem>
              <ListItem>Mieux dormir, mieux digérer, mieux respirer, nourrir son énergie</ListItem>
              <ListItem>Libérer, assouplir et consolider les articulations</ListItem>
            </UnorderedList>
            <Text fontSize={"13px"} textAlign={'center'}>Le qi gong est un outil de la médecine chinoise dont le plus connu est l’acupuncture.</Text>
          </Flex>
        </Flex >}
        {/* AVAILABLE PRODUCTS PART */}
        < Flex flexDirection={"column"} maxWidth="1000px" padding="30px 0 30px" alignSelf={"center"} gap="15px" >
          {/* TITLE */}
          < Text id="titleProductsPart" textAlign={"center"} padding={'0 15%'} fontSize={isLargerThan768 ? "30px" : isLargerThan425 ? "26px" : '20px'} color="#14453D" > PRATIQUES PROPOSÉES (au sein d’un groupe de 7 personnes maximum) :</Text >
          {/* FIRST PRODUCT */}
          {isLargerThan768 ? < Flex width='100%' flexDirection={"row"}>
            <Flex alignItems={'center'}>
              <Image height={"387px"} borderRadius={"20px"} src={require("./meditation.jpg")} />
            </Flex>
            <Flex width={"50%"} flexDirection={"column"} gap="15px" margin="15px">
              <Text textAlign={"center"} padding='0 5%' fontSize={"30px"}>QI GONG et MÉDITATION GUIDÉE</Text>
              <Text color="#14453D" fontSize={"18px"}>A la suite de la pratique du qi gong, le corps et l’esprit sont prêts à être guidés dans un état méditatif.</Text>
              <Text color="#14453D" fontSize={"18px"}>Des guidances variées, qui peuvent être employées ensuite par chacun de façon autonome.</Text>
              <Flex flexDirection={"row"} marginLeft="45px">
                <Icon as={AiOutlineEuroCircle} boxSize={12} />
                <Flex flexDirection={"column"} marginLeft="10px" justifyContent={"center"}>
                  <Text fontSize={"18px"}>Trimestre : 90€</Text>
                  <Text fontSize={"18px"}>1 séance : 20€</Text>
                </Flex>
              </Flex>
              <Flex flexDirection={"row"} marginLeft="45px">
                <Icon as={TbCalendarTime} boxSize={12} />
                <Flex flexDirection={"column"} marginLeft="10px">
                  <Text fontSize={"18px"}>De 10H30 à 11H45</Text>
                  <Flex flexDirection={'row'} gap={'50px'}>
                    <Flex flexDirection={"column"}>
                      <Text fontSize={"18px"}>2<sup>ème</sup> trimestre :</Text>
                      <UnorderedList marginLeft={'25px'} fontSize="16px">
                        <ListItem>8 et 22 Janvier</ListItem>
                        <ListItem>5 et 19 Février</ListItem>
                        <ListItem>4 et 18 Mars</ListItem>
                      </UnorderedList>
                    </Flex>
                    <Flex flexDirection={"column"}>
                      <Text fontSize={"18px"}>3<sup>ème</sup> trimestre :</Text>
                      <UnorderedList marginLeft={'25px'} fontSize="16px">
                        <ListItem>8 et 22 Avril</ListItem>
                        <ListItem>6 et 27 Mai</ListItem>
                        <ListItem>10 et 24 Juin</ListItem>
                      </UnorderedList>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex > : isLargerThan425 ? < Flex width='100%' flexDirection={"column"} alignItems={'center'}>
            <Flex flexDirection={"column"} gap="15px" margin="2%">
              <Text textAlign={"center"} padding='0 5%' fontSize={"26px"}>QI GONG et MÉDITATION GUIDÉE</Text>
              <Image height={"60%"} maxWidth={'60%'} alignSelf={'center'} borderRadius={"20px"} src={require("./meditation.jpg")} />
              <Text color="#14453D" fontSize={"17px"} padding='0 10%'>A la suite de la pratique du qi gong, le corps et l’esprit sont prêts à être guidés dans un état méditatif.</Text>
              <Text color="#14453D" fontSize={"17px"} padding='0 10%'>Des guidances variées, qui peuvent être employées ensuite par chacun de façon autonome.</Text>
              <Flex flexDirection={"column"} alignItems={'center'} gap={'15px'}>
                <Flex flexDirection={"row"} width={'250px'}>
                  <Icon as={AiOutlineEuroCircle} boxSize={10} />
                  <Flex flexDirection={"column"} marginLeft="10px" justifyContent={"center"}>
                    <Text fontSize={"16px"}>Trimestre : 90€</Text>
                    <Text fontSize={"16px"}>1 séance : 20€</Text>
                  </Flex>
                </Flex>
                <Flex flexDirection={"row"} width={'250px'}>
                  <Icon as={TbCalendarTime} boxSize={10} />
                  <Flex flexDirection={"column"} marginLeft="10px">
                    <Text fontSize={"16px"}>De 10H30 à 11H45</Text>
                    <Flex flexDirection={'column'} gap={'10px'}>
                      <Flex flexDirection={"column"}>
                        <Text fontSize={"16px"}>2<sup>ème</sup> trimestre :</Text>
                        <UnorderedList marginLeft={'25px'} fontSize="14px">
                          <ListItem>8 et 22 Janvier</ListItem>
                          <ListItem>5 et 19 Février</ListItem>
                          <ListItem>4 et 18 Mars</ListItem>
                        </UnorderedList>
                      </Flex>
                      <Flex flexDirection={"column"}>
                        <Text fontSize={"16px"}>3<sup>ème</sup> trimestre :</Text>
                        <UnorderedList marginLeft={'25px'} fontSize="14px">
                          <ListItem>8 et 22 Avril</ListItem>
                          <ListItem>6 et 27 Mai</ListItem>
                          <ListItem>10 et 24 Juin</ListItem>
                        </UnorderedList>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex > : < Flex width='100%' flexDirection={"column"} alignItems={'center'}>
            <Flex flexDirection={"column"} gap="15px" margin="2%">
              <Text textAlign={"center"} padding='0 5%' fontSize={"20px"}>QI GONG et MÉDITATION GUIDÉE</Text>
              <Image maxWidth={'80%'} alignSelf={'center'} borderRadius={"20px"} src={require("./meditation.jpg")} />
              <Text color="#14453D" fontSize={"14px"} padding='0 10%'>A la suite de la pratique du qi gong, le corps et l’esprit sont prêts à être guidés dans un état méditatif.</Text>
              <Text color="#14453D" fontSize={"14px"} padding='0 10%'>Des guidances variées, qui peuvent être employées ensuite par chacun de façon autonome.</Text>
              <Flex flexDirection={"column"} alignItems={'center'} gap={'15px'}>
                <Flex flexDirection={"row"} width={'170px'}>
                  <Icon as={AiOutlineEuroCircle} boxSize={7} />
                  <Flex flexDirection={"column"} marginLeft="10px" justifyContent={"center"}>
                    <Text fontSize={"12px"}>Trimestre : 90€</Text>
                    <Text fontSize={"12px"}>1 séance : 20€</Text>
                  </Flex>
                </Flex>
                <Flex flexDirection={"row"} width={'170px'}>
                  <Icon as={TbCalendarTime} boxSize={7} />
                  <Flex flexDirection={"column"} marginLeft="10px">
                    <Text fontSize={"12px"}>De 10H30 à 11H45</Text>
                    <Flex flexDirection={'column'} gap={'10px'}>
                      <Flex flexDirection={"column"}>
                        <Text fontSize={"12px"}>2<sup>ème</sup> trimestre :</Text>
                        <UnorderedList marginLeft={'25px'} fontSize="11px">
                          <ListItem>8 et 22 Janvier</ListItem>
                          <ListItem>5 et 19 Février</ListItem>
                          <ListItem>4 et 18 Mars</ListItem>
                        </UnorderedList>
                      </Flex>
                      <Flex flexDirection={"column"}>
                        <Text fontSize={"12px"}>3<sup>ème</sup> trimestre :</Text>
                        <UnorderedList marginLeft={'25px'} fontSize="11px">
                          <ListItem>8 et 22 Avril</ListItem>
                          <ListItem>6 et 27 Mai</ListItem>
                          <ListItem>10 et 24 Juin</ListItem>
                        </UnorderedList>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex >}
          {/* SECOND PRODUCT */}
          {isLargerThan768 ? <Flex flexDirection={"row"} width={'100%'}>
            <Flex width={"50%"} flexDirection={"column"} gap="15px" margin="15px">
              <Text textAlign={"center"} padding='0 100px' fontSize={"30px"}>QI GONG
                des SAISONS</Text>
              <Text color="#14453D" fontSize={"18px"}>Chaque saison contient une énergie particulière et nous la transmet. Nous pouvons la sentir dans notre corps, et aussi dans nos émotions.</Text>
              <Text color="#14453D" fontSize={"18px"}>Des organes sont associés à chacune. Par la pratique de mouvements traditionnels, leur énergie vitale est stimulée.</Text>
              <Flex flexDirection={"row"} marginLeft="45px">
                <Icon as={AiOutlineEuroCircle} boxSize={12} />
                <Flex flexDirection={"column"} marginLeft="10px" justifyContent={"center"}>
                  <Text fontSize={"18px"}>Une matinée : 50€</Text>
                </Flex>
              </Flex>
              <Flex flexDirection={"row"} marginLeft="45px">
                <Icon as={TbCalendarTime} boxSize={12} />
                <Flex flexDirection={"column"} marginLeft="10px">
                  <Text fontSize={"18px"}>Le samedi de 9H à 12H</Text>
                  <UnorderedList marginLeft={'25px'} fontSize="16px" spacing={'5px'}>
                    <ListItem>20 janvier (Hiver : énergie des Reins et de la Vessie)</ListItem>
                    <ListItem>17 février (Printemps : énergie du Foie et de la Vésicule Biliaire)</ListItem>
                    <ListItem>16 mars (Printemps : énergie du Foie et de la Vésicule Biliaire )</ListItem>
                    <ListItem>13 avril (Intersaison : énergie de Rate-Pancréas et Estomac)</ListItem>
                    <ListItem>25 mai (Été : énergie du Coeur et de l'Intestin Grêle)</ListItem>
                    <ListItem>22 juin (Été : énergie du Coeur et de l'Intestin Grêle)</ListItem>
                  </UnorderedList>
                </Flex>
              </Flex>
            </Flex>
            <Flex alignItems={"center"}>
              <Image width={'100%'} borderRadius={"20px"} height={"385px"} src={require("./siasons.jpg")} />
            </Flex>
          </Flex > : isLargerThan425 ? <Flex flexDirection={"row"} width={'100%'}>
            <Flex flexDirection={"column"} gap="15px" margin="2%">
              <Text textAlign={"center"} padding='0 5%' fontSize={"25px"}>QI GONG des SAISONS</Text>
              <Image height={"60%"} maxWidth={'60%'} alignSelf={'center'} borderRadius={"20px"} src={require("./siasons.jpg")} />
              <Text color="#14453D" fontSize={"17px"} padding='0 10%'>Chaque saison contient une énergie particulière et nous la transmet. Nous pouvons la sentir dans notre corps, et aussi dans nos émotions.</Text>
              <Text color="#14453D" fontSize={"17px"} padding='0 10%'>Des organes sont associés à chacune. Par la pratique de mouvements traditionnels, leur énergie vitale est stimulée.</Text>
              <Flex flexDirection={"column"} alignItems={'center'}>
                <Flex flexDirection={"row"} width={'250px'}>
                  <Icon as={AiOutlineEuroCircle} boxSize={10} />
                  <Flex flexDirection={"column"} marginLeft="10px" justifyContent={"center"}>
                    <Text fontSize={"16px"}>Une matinée : 50€</Text>
                  </Flex>
                </Flex>
                <Flex flexDirection={"row"} width={'250px'}>
                  <Icon as={TbCalendarTime} boxSize={10} />
                  <Flex flexDirection={"column"} marginLeft="10px">
                    <Text fontSize={"16px"}>Le samedi de 9H à 12H</Text>
                    <UnorderedList marginLeft={'25px'} fontSize="14px" spacing={'5px'}>
                      <ListItem>20 janvier (Hiver : énergie des Reins et de la Vessie)</ListItem>
                      <ListItem>17 février (Printemps : énergie du Foie et de la Vésicule Biliaire)</ListItem>
                      <ListItem>16 mars (Printemps : énergie du Foie et de la Vésicule Biliaire )</ListItem>
                      <ListItem>13 avril (Intersaison : énergie de Rate-Pancréas et Estomac)</ListItem>
                      <ListItem>25 mai (Été : énergie du Coeur et de l'Intestin Grêle)</ListItem>
                      <ListItem>22 juin (Été : énergie du Coeur et de l'Intestin Grêle)</ListItem>
                    </UnorderedList>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex > : <Flex flexDirection={"row"} width={'100%'}>
            <Flex flexDirection={"column"} gap="15px" margin="2%">
              <Text textAlign={"center"} padding='0 5%' fontSize={"20px"}>QI GONG des SAISONS</Text>
              <Image maxWidth={'80%'} alignSelf={'center'} borderRadius={"20px"} src={require("./siasons.jpg")} />
              <Text color="#14453D" fontSize={"14px"} padding='0 10%'>Chaque saison contient une énergie particulière et nous la transmet. Nous pouvons la sentir dans notre corps, et aussi dans nos émotions.</Text>
              <Text color="#14453D" fontSize={"14px"} padding='0 10%'>Des organes sont associés à chacune. Par la pratique de mouvements traditionnels, leur énergie vitale est stimulée.</Text>
              <Flex flexDirection={"column"} alignItems={'center'}>
                <Flex flexDirection={"row"} width={'200px'}>
                  <Icon as={AiOutlineEuroCircle} boxSize={7} />
                  <Flex flexDirection={"column"} marginLeft="10px" justifyContent={"center"}>
                    <Text fontSize={"12px"}>Une matinée : 50€</Text>
                  </Flex>
                </Flex>
                <Flex flexDirection={"row"} width={'200px'}>
                  <Icon as={TbCalendarTime} boxSize={7} />
                  <Flex flexDirection={"column"} marginLeft="10px">
                    <Text fontSize={"12px"}>Le samedi de 9H à 12H</Text>
                    <UnorderedList marginLeft={'25px'} fontSize="11px" spacing={'5px'}>
                      <ListItem>20 janvier (Hiver : énergie des Reins et de la Vessie)</ListItem>
                      <ListItem>17 février (Printemps : énergie du Foie et de la Vésicule Biliaire)</ListItem>
                      <ListItem>16 mars (Printemps : énergie du Foie et de la Vésicule Biliaire )</ListItem>
                      <ListItem>13 avril (Intersaison : énergie de Rate-Pancréas et Estomac)</ListItem>
                      <ListItem>25 mai (Été : énergie du Coeur et de l'Intestin Grêle)</ListItem>
                      <ListItem>22 juin (Été : énergie du Coeur et de l'Intestin Grêle)</ListItem>
                    </UnorderedList>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex >}
        </Flex >
        {/* CAROLE THIERY PART */}
        {isLargerThan768 ? < Flex id="presentationPart" width="100%" backgroundColor={"#4C8F6042"} justifyContent="center" >
          <Flex flexDirection={"column"} width="750px" padding="45px 0" gap="15px">
            <Text fontSize={"42px"} textAlign="center" fontWeight="500">Carole THIÉRY</Text>
            <Flex flexDirection={"row"}>
              <Image borderRadius={"20px"} src={require("./carole_thiery.jpg")} width="50%" textAlign={"end"} />
              <Flex flexDirection={"column"} gap="10px" width="50%" textAlign={"start"} padding="15px" justifyContent="center">
                <Text fontSize={"18px"} marginBottom="15px">Formée à l’école de médecine chinoise et de qi gong de Sevrier, et diplômée par la CFMTC.</Text>
                <Flex flexDirection={"row"} gap="10px">
                  <Icon as={BsTelephoneFill} boxSize={8} border="1px solid black" borderRadius={"50%"} padding="5px" />
                  <Text fontSize={"18px"}>07 70 70 81 21</Text>
                </Flex>
                <Flex flexDirection={"row"} gap="10px">
                  <Icon as={IoMdMail} boxSize={8} border="1px solid black" borderRadius={"50%"} padding="5px" />
                  <Text fontSize={"18px"}><a href="mailto:pratiquechinoiseannecy@gmail.com">pratiquechinoiseannecy@gmail.com</a></Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex > : isLargerThan425 ? < Flex id="presentationPart" width="100%" backgroundColor={"#4C8F6042"} justifyContent="center" >
          <Flex flexDirection={"column"} alignItems={'center'} maxW={'415px'} padding="5% 0" gap="15px">
            <Text fontSize={"30px"} textAlign="center" fontWeight="500">Carole THIÉRY</Text>
            <Image borderRadius={"20px"} src={require("./carole_thiery.jpg")} maxW={'90%'} textAlign={"end"} />
            <Flex flexDirection={"column"} gap="10px" textAlign={"start"} padding="15px" justifyContent="center">
              <Text fontSize={"16px"} marginBottom="15px">Formée à l’école de médecine chinoise et de qi gong de Sevrier, et diplômée par la CFMTC.</Text>
              <Flex flexDirection={"row"} gap="10px">
                <Icon as={BsTelephoneFill} boxSize={6} border="1px solid black" borderRadius={"50%"} padding="5px" />
                <Text fontSize={"16px"}>07 70 70 81 21</Text>
              </Flex>
              <Flex flexDirection={"row"} gap="10px">
                <Icon as={IoMdMail} boxSize={6} border="1px solid black" borderRadius={"50%"} padding="5px" />
                <Text fontSize={"16px"}><a href="mailto:pratiquechinoiseannecy@gmail.com">pratiquechinoiseannecy@gmail.com</a></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex > : < Flex id="presentationPart" width="100%" backgroundColor={"#4C8F6042"} justifyContent="center" >
          <Flex flexDirection={"column"} alignItems={'center'} maxW={'415px'} padding="5% 0" gap="15px">
            <Text fontSize={"22px"} textAlign="center" fontWeight="500">Carole THIÉRY</Text>
            <Image borderRadius={"20px"} src={require("./carole_thiery.jpg")} maxW={'90%'} textAlign={"end"} />
            <Flex flexDirection={"column"} gap="10px" textAlign={"start"} padding="15px" justifyContent="center">
              <Text fontSize={"14px"} marginBottom="15px">Formée à l’école de médecine chinoise et de qi gong de Sevrier, et diplômée par la CFMTC.</Text>
              <Flex flexDirection={"row"} gap="10px">
                <Icon as={BsTelephoneFill} boxSize={6} border="1px solid black" borderRadius={"50%"} padding="5px" />
                <Text fontSize={"14px"}>07 70 70 81 21</Text>
              </Flex>
              <Flex flexDirection={"row"} gap="10px">
                <Icon as={IoMdMail} boxSize={6} border="1px solid black" borderRadius={"50%"} padding="5px" />
                <Text fontSize={"14px"}><a href="mailto:pratiquechinoiseannecy@gmail.com">pratiquechinoiseannecy@gmail.com</a></Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex >}
        {/* MAP PART */}
        {isLargerThan768 ? < Flex id="planPart" flexDirection="column" width="100%" backgroundColor={"#7AB367"} textAlign="center" padding={"30px 0"} >
          <Text fontSize={"42px"}>Lieu des séances</Text>
          <Text fontSize={"22px"}>Espace Hygie</Text>
          <a href='https://espacehygieannecy.fr/' target={"_blank"} rel="noreferrer">www.espacehygieannecy.fr</a>
          <Flex marginTop={"35px"} justifyContent={"center"}><iframe style={{ borderRadius: "20px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2775.5123100455007!2d6.11838571585266!3d45.92105957910902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478b85634aa21c37%3A0x4bf2618ef9ad69b5!2s79%20Bd%20du%20Fier%2C%2074000%20Annecy!5e0!3m2!1sen!2sfr!4v1678551581908!5m2!1sen!2sfr"
            width="600px" height="450px" loading="lazy" title='room-address'></iframe></Flex>
        </Flex > : isLargerThan425 ? < Flex id="planPart" flexDirection="column" width="100%" backgroundColor={"#7AB367"} textAlign="center" padding={"20px 0"} >
          <Text fontSize={"30px"}>Lieu des séances</Text>
          <Text fontSize={"18px"}>Espace Hygie</Text>
          <a style={{ fontSize: "14px" }} href='https://espacehygieannecy.fr/' target={"_blank"} rel="noreferrer">www.espacehygieannecy.fr</a>
          <Flex marginTop={"20px"} justifyContent={"center"}><iframe style={{ borderRadius: "20px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2775.5123100455007!2d6.11838571585266!3d45.92105957910902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478b85634aa21c37%3A0x4bf2618ef9ad69b5!2s79%20Bd%20du%20Fier%2C%2074000%20Annecy!5e0!3m2!1sen!2sfr!4v1678551581908!5m2!1sen!2sfr"
            width="90%" height="300px" loading="lazy" title='room-address'></iframe></Flex>
        </Flex > : < Flex id="planPart" flexDirection="column" width="100%" backgroundColor={"#7AB367"} textAlign="center" padding={"5px 0"} >
          <Text fontSize={"22px"}>Lieu des séances</Text>
          <Text fontSize={"15px"}>Espace Hygie</Text>
          <a style={{ fontSize: "12px" }} href='https://espacehygieannecy.fr/' target={"_blank"} rel="noreferrer">www.espacehygieannecy.fr</a>
          <Flex marginTop={"10px"} marginBottom={'5px'} justifyContent={"center"}>
            <iframe style={{ borderRadius: "20px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2775.5123100455007!2d6.11838571585266!3d45.92105957910902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478b85634aa21c37%3A0x4bf2618ef9ad69b5!2s79%20Bd%20du%20Fier%2C%2074000%20Annecy!5e0!3m2!1sen!2sfr!4v1678551581908!5m2!1sen!2sfr"
              width="90%" height="300px" loading="lazy" title='room-address'></iframe>
          </Flex>
        </Flex >}
        {/* FOOTER */}
        < Flex backgroundColor={"#051210"} color={"whiteAlpha.600"} padding="2%" justifyContent={"center"} >
          <Text fontSize={isLargerThan768 ? '16px' : isLargerThan425 ? '14px' : '12px'}>© 2023 Hugo Rouèche - All rights reserved</Text>
        </Flex >
      </Flex >
    </Box >
  );
}

export default App;
